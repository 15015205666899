<template>
  <section id="Custom" style="max-height: 720px" class="my-xl-12">

  <v-container class="px-4 py-12 mt-5">
    <v-responsive
      height="100%"
      width="100%"
    >
      <v-row class="mx-0">
        <v-col cols="12" class="text-center pb-0">
          <span class="title-contact justify-center d-flex" style="letter-spacing: 0.1em !important" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly, 'px-5': $vuetify.breakpoint.smAndDown}">Personaliza tu carta</span>
        </v-col>
        <v-col cols="12" class="text-center pt-0">
          <p class="text-center info-contact" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly, 'px-5': $vuetify.breakpoint.smAndDown}">SENCILLO PARA TI, ÉXITO PARA TI</p>
        </v-col>
      </v-row>
      <v-row class="mx-0" justify="center" align="center">
        <v-col cols="6" sm="2" md="2" lg="2" class="text-center mx-md-6 mx-lg-6" :class="{'pt-9': $vuetify.breakpoint.lgAndDown, 'pt-5': $vuetify.breakpoint.xlOnly }">
          <v-avatar :size="$vuetify.breakpoint.mdAndUp ? '154' : '94'">
            <v-img
              :src="require('@/assets/Home/color.png')"
              class="white--text"
            ></v-img>
          </v-avatar>
          <span class="d-block custom-title">Con los colores de tu marca</span>
        </v-col> 
        <v-col cols="6" sm="2" md="2" lg="2" class="text-center mx-md-6 mx-lg-6">
          <v-avatar :size="$vuetify.breakpoint.mdAndUp ? '154' : '94'">
           <v-img
              :src="require('@/assets/Home/logo.png')"
              class="white--text"
              width="154px"
              height="154px"
            ></v-img>
          </v-avatar>
          <span class="d-block custom-title">Incluye tu logo</span>
        </v-col>
        <v-col cols="6" sm="2" md="2" lg="2" class="text-center mx-md-6 mx-lg-6">
          <v-avatar :size="$vuetify.breakpoint.mdAndUp ? '154' : '94'">
           <v-img
              :src="require('@/assets/Home/gps.png')"
              class="white--text"
            ></v-img>
          </v-avatar>
          <span class="d-block custom-title">Localización</span>
        </v-col>
        <v-col cols="6" sm="2" md="2" lg="2" class="text-center mx-md-6 mx-lg-6">
          <v-avatar :size="$vuetify.breakpoint.mdAndUp ? '154' : '94'">
            <v-img
              :src="require('@/assets/Home/personaliza.png')"
              class="white--text"
            ></v-img>
          </v-avatar>
          <span class="d-block custom-title">Personaliza tu carta</span>
        </v-col>
        
      </v-row>
    </v-responsive>
  </v-container>
  </section>
</template>

<script>
  export default {
    name: 'Custom',
  }
</script>